import React, { Component } from 'react';

class Medhjalpa extends Component {

  render () {
    return (
<center>
<div className="info">

<h1>Medhjälpare</h1>


<p>
 Här finns dokument som du som medhjälpare kan ha nytta av. Dokumenten finns också tillgängliga i pärmen på Allégården.<br/>
 <b>Nyckelperson:</b> inför din kväll, gå gärna igenom dessa listor tillsammans med någon som varit med tidigare eller någon i styrelsen.
</p>

<table class="defList">
 <tr class="sod konsert">
  <td>Plan för hela kvällen</td>
  <td>
   Checklistor för nyckelperson och kaféansvarig.<br/>
   <b>Nyckelperson:</b>
   <a href="filer/2024/nyckelperson_2024.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>
   <a href="filer/2024/nyckelperson_2024.doc"><img src="img/ikoner/doc.png" class="doc" alt="doc:" /> Word</a>   
   <br/>
   <b>Kaféahandledning:</b>
   <a href="filer/2024/kafehandledning_2024.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>, <a href="filer/2024/kafehandledning_2024.doc"><img src="img/ikoner/doc.png" class="doc" alt="doc:" /> Word</a>   <br/>
  </td>
 </tr>
 <tr class="sod konsert">
  <td>Fika</td>
  <td>
   Inköpslista till fiket.<br/>
   <b>Kaféansvarig:</b>
   Om du vill, utgå från denna lista när du handlar.<br/>
   <a href="filer/fika-8.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>, <a href="filer/fika-8.docx"><img src="img/ikoner/doc.png" class="doc" alt="doc:" /> Word</a>  </td>
 </tr>
 <tr class="sod konsert">
  <td>Arbetsschema</td>
  <td>
   Kan användas för att hålla ordning på vilka medhjälpare som gör vad när.<br/>
   <b>Nyckelperson:</b>
   Be dina medhjälpare skriva upp sig på de pass
   de vill ta.<br/>
   <a href="filer/arbetsschema-1.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>, <a href="filer/arbetsschema-1.doc"><img src="img/ikoner/doc.png" class="doc" alt="doc:" /> Word</a>  </td>
 </tr>
 <tr class="konsert">
  <td>Entré</td>
  <td>

Lathunden fungerar som instruktion för den som sitter i entrén, och skylten placeras så att
besökarna ser den.
    <br/>   

  <p>Ordinarie entré 180 kr.</p><ul><li>Lathund: <a href="filer/entrevard_180-11.pdf">
   <img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>, 
   <a href="filer/entrevard_180-11.docx">Word</a></li><li>Skylt: <a href="filer/entreskylt_180-5.pdf">
   <img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>,
   <a href="filer/entreskylt_180-5.doc"><img src="img/ikoner/doc.png" class="doc" alt="doc:" />
   Word</a></li></ul><p>Ordinarie entré 160 kr.</p><ul>
   <li>Lathund: <a href="filer/entrevard_160-11.pdf">
   <img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>,
   <a href="filer/entrevard_160-11.docx">Word</a></li><li>Skylt: <a href="filer/entreskylt_160-5.pdf">
   <img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>,
   <a href="filer/entreskylt_160-5.doc">
   <img src="img/ikoner/doc.png" class="doc" alt="doc:" /> Word</a></li></ul>
   </td>
 </tr>
 <tr class="konsert">
  <td>Medlemsformulär</td>
  <td>
   Formulär för nya medlemmar att fylla i i entrén. Behövs alla kvällar.<br/>
   <a href="filer/medlemsformular_6.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>, <a href="filer/medlemsformular_6.docx">Word</a>  </td>
 </tr>
 <tr class="konsert">
  <td>Medlemsregister</td>
  <td>
   Medlemsregistret finns av sekretesskäl inte här men tas med av någon i styrelsen under artistkvällar. Medlemsregistret behövs i entrén under alla kvällar.<br/>
  </td>
 </tr>
 <tr class="sod konsert">
  <td>Kvällsredovisning</td>
  <td>
   Blankett som nyckelpersonen använder för ekonomisk redovisning av intäkter (kafé/entré/medlemskap) och utgifter (kafé) för en kväll.<br/>
   Se till att få kvitton, växelkassa och övriga uppgifter från kaféansvarig. Se till att få pengar från den som sitter i entrén. Kontrollera att du har alla uppgifter som behövs för att fylla i redovisningen.<br/>
   <a href="filer/2024/kvallsredovisning-24.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>, <a href="filer/2024/kvallsredovisning-24.docx"><img src="img/ikoner/doc.png" class="doc" alt="doc:" /> Word</a>  </td>
 </tr>
 <tr class="sod konsert">
  <td>Redovisning av besöksantal</td>
  <td>
   Blankett för redovisning av besökare under en kväll.<br/>
   Fylls i löpande av kassapersonal under konsertkväll. Under spel- och danskväll uppskatta antalet besökare. Bifoga ifylld blankett till kvällsredovisningen. 
   <br/>
   <a href="filer/besoksantal.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>, <a href="filer/besoksantal.docx">Word</a>  </td>
 </tr>
 <tr class="konsert">
  <td>Presentation av artisterna</td>
  <td>
   Checklista som underlag för den som presenterar artisten.<br/>
   <b>Styrelsevärd:</b>
   Hitta någon som presenterar artisten (eller gör det själv)!<br/>
   <a href="filer/presentation_artister-3.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>, <a href="filer/presentation_artister-3.doc"><img src="img/ikoner/doc.png" class="doc" alt="doc:" /> Word</a>  </td>
 </tr>
 <tr class="konsert">
  <td>Stolars placering i stora salen under konsert</td>
  <td>
   Ritning som beskriver hur stora salen ska möbleras under konsert.<br/>
   <b>Nyckelperson:</b>
   Möblera salen enligt ritningen
   <a href="filer/stolar.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>  </td>
 </tr>
 <tr class="sod konsert">
  <td>Checklista&nbsp;för&nbsp;stängning</td>
  <td>
   Checklista att använda vid stängning.<br/>
   <b>Nyckelperson:</b>
   Bocka av alla punkter när du städar efter kvällen.<br/>
   <a href="filer/2024/checklista24.pdf"><img src="img/ikoner/pdf.png" class="pdf" alt="pdf:" /> PDF</a>
   <a href="filer/2024/checklista24.docx"><img src="img/ikoner/doc.png" class="doc" alt="doc:" /> DOC</a>  </td>
       </tr>
</table>
<br/>
<br/>
<br/>

</div>
</center>

);
}

}

export default Medhjalpa;


