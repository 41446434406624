import React, { Component } from 'react';

class Botten extends Component {

  render () {
    return(
      <div>
      <br/>
      <center>
      <a href="https://www.bilda.nu/" target="_blank">
        <img src="img/bilda.webp" style={{"height": "100px",
          "maxWidth": "70%",
        "padding-bottom": "10px", "align": "center"}}/>
    </a>

      <a href="https://goteborg.se/" target="_blank">
        <img src="img/gbg_li_rgb.png" style={{
          "maxWidth": "70%",
          "height":"120px",
        "padding": "10px", "align": "center"}}/>
        <br/>
    </a>

      <a href="https://www.kulturradet.se/" target="_blank">
        <img src="img/kulturradet_logo.gif" style={{"height": "40px",
          "maxWidth":"70%",
        "padding": "10px", "align": "center"}}/>
    </a>
    <br/><br/>
    <br/>


    <table className="bottom-links">
    <tr>
    <td>
<a href="http://www.facebook.com/pages/Folkmusikkaf%C3%A9et-All%C3%A9g%C3%A5rden/148025221939689"
target="_blank">
<span class="et_pb_image_wrap">
<img src="/img/ikoner/fb.png" style={{"height": "70px"}} alt="" /></span></a>
  </td>
  <td>
<a href="https://www.instagram.com/fmkgbg/?hl=en"
target="_blank">
<span class="et_pb_image_wrap">
<img src="/img/ikoner/insta.png" style={{"height": "70px"}} alt="" /></span></a>
</td>
</tr>
</table>
<br/>
<a href="https://goo.gl/maps/MN9oLjYhoPnDbwLy5" target="_blank" style={{"color": "white", "font-size": "20px"}}><b>Södra Allégatan 4, Göteborg</b></a>
      </center>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      </div>
      );
  }

}

export default Botten;



