import React, { Component } from 'react';

class Kontakt extends Component {



  render () {

    return (
<center>
<div className="info kontakt kompakt-mer" > 
<center><h1>Kontakt</h1></center>
  <h2>Bokning</h2>

  E-post:

  <a href="mailto:artistbokare@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> artistbokare@folkmusikkafeet.net</a>

  <h2>Kontakta föreningen</h2>

  E-post:

  <a href="mailto:folkmusikkafeet@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> folkmusikkafeet@folkmusikkafeet.net</a>

  <br/><br/>
  <b>Besöksadress</b>:<br/>
  Folkmusikkaféet<br/>
  Allégården<br/>
  Södra Allégatan 4<br/>
  413 01 Göteborg<br/><br/>

  <b>Fakturaadress</b>:<br/>
  Folkmusikkaféet c/o Kent Molin<br/>
  Basungatan 14<br/>
  42140 Västra Frölunda<br/>
  Plusgiro: 107741-1<br/>
  Swish:<br/>
  Kursavgift: 123608545<br/>
  Medlemsavgift: 1236279434<br/>
  Organisationsnummer: 857205-7803<br/><br />
  Integritetspolicy:<br/>
  <a href="filer/integritetspolicy_fmk.pdf"><img src="img/ikoner/pdf.png" className="pdf" alt="pdf:" /> PDF</a>
<br/><br/>
  Klagomål/synpunkter:
<br/>
  <a href="mailto:folkmusikkafeet@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> folkmusikkafeet@folkmusikkafeet.net</a><br/>
<br/><br/>
  <h2>Kontaktpersoner</h2>

  Styrelsen:<br/><br/>

     <b>Ordförande, ljudteknikansvarig:</b><br/>
     <div className="inskutt">
     Fredy Samuel Lundh<br/>
      <a href="mailto:ordforande@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> ordforande@folkmusikkafeet.net</a><br/>
      0739758173 <br/>
    </div>

    <br/>
     <b>Rapportansvarig, medlem i ekonomigruppen:</b><br/>
    <div className="inskutt">
     Birgitta Rydberg<br/>
     <a href="mailto:birgitta@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> birgitta@folkmusikkafeet.net</a>
     </div>
<br/>
     <b>Kassör, medlem i ekonomigruppen:</b><br/>
     <div className="inskutt">
     Kent Molin<br/>
     <a href="mailto:kassor@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> kassor@folkmusikkafeet.net</a><br/>
<br/>
    </div>
     <b>Ansvarig för nyhetsbrev till medlemmar:</b><br/>
     <div className="inskutt">
     Emma Lagerberg<br/>
     <a href="mailto:pr@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> pr@folkmusikkafeet.net</a><br/>
<br/>
    </div>
     <b>Ansvarig för dansstugor och danskurser samt medlemsregister:</b><br/>
     <div className="inskutt">
     Eva Björnefors<br/>
       <a href="mailto:dans@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> dans@folkmusikkafeet.net</a></div><br/>

     <b>Sekreterare, medlem i kafégruppen:</b><br/>
      <div className="inskutt">
     Karin Sandström<br/>
     <a href="mailto:karin.sandstrom@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> karin.sandstrom@folkmusikkafeet.net</a><br/>
      </div>
      <br/>
     <b>Medlem i kafégruppen, kontakt för GP:s kalendarium:</b><br/>
     <div className="inskutt">
     Bo Nyström<br/>
     <a href="mailto:bo@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> bo.nystrom@folkmusikkafeet.net</a><br/>
<br/>
    </div>
     <b>Ansvarig för spelkurser samt bokning av paus- och introspelmän<br/> och spel- och visstugor, programsammanställare:</b><br/>
     <div>
      <div className="inskutt">

     Lars-Gunnar Franzén<br/>
     <a href="mailto:lars-gunnar@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> lars-gunnar@folkmusikkafeet.net</a>
      </div>
     </div>
<br/>
     <b>Kontraktskrivare, logibokningsansvarig, medlem i ekonomigruppen:</b><br/>
     <div className="inskutt">
     Monica Misson<br />
  <a href="mailto:monica@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> monica@folkmusikkafeet.net</a><br/>   
  <br/>     
  </div>
<br/>
     <b>Lokalansvarig:</b><br/>
     <div className="inskutt">
     Björn Johnsson<br/>
  <a href="mailto:bjorn@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> bjorn@folkmusikkafeet.net</a><br/>     
  </div>

     <b>Adjungerande till styrelsen:</b><br/>
     <div className="inskutt">
     Jon Johnsson<br/>
  <a href="mailto:jon@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> jon@folkmusikkafeet.net</a><br/>   
      <br/></div>

  <b>Valberedning:</b>
<br/><br/>
<div className="inskutt">
  <b>Sammankallande:</b><br/>
  <div className="inskutt">
  Peter Nordqvist<br/>
  <a href="mailto:valberedningen@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> valberedningen@folkmusikkafeet.net</a><br/>
</div>
<br/>
  <b>Ledamot:</b><br/>
  <div className="inskutt">
  Ingela Lindberg<br/>
  <a href="mailto:valberedningen@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> valberedningen@folkmusikkafeet.net</a><br/>
  </div>
<br/>
  <b>Ledamot:</b><br/>
  <div className="inskutt">
  Ingegerd Oad<br/>
  <a href="mailto:valberedningen@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> valberedningen@folkmusikkafeet.net</a><br/>
  </div>
      <br/>
      <b>Ledamot:</b><br/>

  <div className="inskutt">
  Lilian Håkansson<br/>
  <a href="mailto:valberedningen@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> valberedningen@folkmusikkafeet.net</a><br/>
      </div>


<br/>


</div>
  <b>Revisorer:</b>

  <div className="inskutt">
  Peter Nordqvist<br/>
  <a href="mailto:internrevisor@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> internrevisor@folkmusikkafeet.net</a><br/>

  </div><br/>
  <div className="inskutt">
  Folke Bergqvist<br/>
  <a href="mailto:internrevisor@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> internrevisor@folkmusikkafeet.net</a><br/>

  </div><br/>
  <b>Adjungerade till styrelsen:</b>
  <div className="inskutt">
    <br/>
     <b>Webmaster:</b><br/>
     <div className="inskutt">
     Rickard Hallerbäck<br/>
  <a href="mailto:webmaster@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> webmaster@folkmusikkafeet.net</a><br/>
</div>
      <br/>


 </div>

</div>
</center>
);
}

}


export default Kontakt;



